import { useCallback, useEffect, useRef, useState } from 'react';

import { RealtimeClient } from '@theodoreniu/realtime-api-beta';
import { ItemType } from '@theodoreniu/realtime-api-beta/dist/lib/client.js';
import { WavRecorder, WavStreamPlayer } from '../lib/wavtools';
import { clientHiChinese, clientHiEnglish, notDisplay, products } from '../utils/conversation_config.js';
import { WavRenderer } from '../utils/wav_renderer';

import { Mic, X, Zap } from 'react-feather';
import { Button } from '../components/button/Button';
import { Toggle } from '../components/toggle/Toggle';
import './ConsolePage.scss';
import ReactMarkdown from 'react-markdown';
import CameraComponent from '../components/CameraComponent';

import * as news from '../tools/news';
import * as location from '../tools/location';
import * as stock_recommend from '../tools/stock_recommend';
import * as products_recommend from '../tools/products_recommend';
import * as demo from '../tools/demo';
import * as feishu from '../tools/feishu';
import * as quote from '../tools/quote';
import * as exchange_rate_aim from '../tools/exchange_rate_aim';
import * as exchange_rate_list from '../tools/exchange_rate_list';
import * as exchange_rate_configs from '../tools/exchange_rate_configs';

import Painting from '../components/Painting';
import EndpointSettingComponent from '../components/Settings';
import FileUploadComponent from '../components/FileUploadComponent';
import ProductList from '../components/ProductList';
import { getDefaultInstructions, replaceInstructions, setInstructions } from '../utils/instructions';

export const IS_DEBUG: boolean = window.location.href.includes('localhost');

export const MS_GRAPH_TOKEN: string = process.env.MS_GRAPH_TOKEN || '';


/**
 * Type for result from get_weather() function call
 */
interface Coordinates {
  lat: number;
  lng: number;
  location?: string;
  temperature?: {
    value: number;
    units: string;
  };
  wind_speed?: {
    value: number;
    units: string;
  };
}

/**
 * Type for all event logs
 */
interface RealtimeEvent {
  time: string;
  source: 'client' | 'server';
  count?: number;
  event: { [key: string]: any };
}

export function ConsolePage() {

  /**
   * Instantiate:
   * - WavRecorder (speech input)
   * - WavStreamPlayer (speech output)
   * - RealtimeClient (API client)
   */
  const wavRecorderRef = useRef<WavRecorder>(
    new WavRecorder({ sampleRate: 24000 })
  );
  const wavStreamPlayerRef = useRef<WavStreamPlayer>(
    new WavStreamPlayer({ sampleRate: 24000 })
  );
  const clientRef = useRef<RealtimeClient>(
    new RealtimeClient(
      {
        apiKey: localStorage.getItem('key') || '',
        url: localStorage.getItem('endpoint') || '',
        debug: false,
        dangerouslyAllowAPIKeyInBrowser: true
      }
    )
  );

  /**
   * References for
   * - Rendering audio visualization (canvas)
   * - Autoscaling event logs
   * - Timing delta for event log displays
   */
  const clientCanvasRef = useRef<HTMLCanvasElement>(null);
  const serverCanvasRef = useRef<HTMLCanvasElement>(null);
  const eventsScrollHeightRef = useRef(0);
  const eventsScrollRef = useRef<HTMLDivElement>(null);
  const startTimeRef = useRef<string>(new Date().toISOString());

  /**
   * All of our variables for displaying application state
   * - items are all conversation items (dialog)
   * - realtimeEvents are event logs, which can be expanded
   * - memoryKv is for set_memory() function
   * - coords, marker are for get_weather() function
   */
  const [items, setItems] = useState<ItemType[]>([]);
  const [realtimeEvents, setRealtimeEvents] = useState<RealtimeEvent[]>([]);
  const [expandedEvents, setExpandedEvents] = useState<{
    [key: string]: boolean;
  }>({});
  const [isConnected, setIsConnected] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);
  const [connectMessage, setConnectMessage] = useState('Awaiting Connection...');
  const [canPushToTalk, setCanPushToTalk] = useState(true);
  const [isRecording, setIsRecording] = useState(false);
  const [memoryKv, setMemoryKv] = useState<{ [key: string]: any }>({});
  const [coords, setCoords] = useState<Coordinates | null>({
    lat: 39.9841,
    lng: 116.3125
  });
  const [marker, setMarker] = useState<Coordinates | null>(null);

  /**
   * Night mode toggle
   */
  const [isNightMode, setIsNightMode] = useState(false);
  useEffect(() => {
    const client = clientRef.current;
    if (client.isConnected()) {
      client.updateSession({
        instructions: isNightMode ? replaceInstructions('你的界面现在是白天模式', '你的界面现在是夜间模式')
          : replaceInstructions('你的界面现在是夜间模式', '你的界面现在是白天模式')
      });
      document.body.classList.toggle('night-mode');
    }
  }, [isNightMode]);

  const toggleNightMode = () => {
    // setIsNightMode(!isNightMode);
    document.body.classList.toggle('night-mode');
  };

  /**
   * Utility for formatting the timing of logs
   */
  const formatTime = useCallback((timestamp: string) => {
    const startTime = startTimeRef.current;
    const t0 = new Date(startTime).valueOf();
    const t1 = new Date(timestamp).valueOf();
    const delta = t1 - t0;
    const hs = Math.floor(delta / 10) % 100;
    const s = Math.floor(delta / 1000) % 60;
    const m = Math.floor(delta / 60_000) % 60;
    const pad = (n: number) => {
      let s = n + '';
      while (s.length < 2) {
        s = '0' + s;
      }
      return s;
    };
    return `${pad(m)}:${pad(s)}.${pad(hs)}`;
  }, []);

  /**
   * Connect to conversation:
   * WavRecorder tasK speech input, WavStreamPlayer output, client is API client
   */
  const connectConversation = useCallback(async () => {
    if (!localStorage.getItem('endpoint')) {
      setIsConnected(false);
      setIsConnecting(false);
      setConnectMessage('Please set your Target URI.');
      return;
    }

    if (!localStorage.getItem('key')) {
      setIsConnected(false);
      setIsConnecting(false);
      setConnectMessage('Please set your Key.');
      return;
    }

    setIsConnecting(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;

    // Connect to realtime API
    try {
      await client.connect();
    } catch (e) {
      console.error(e);
      const tip = `链接失败，如果您确定配置信息无误，可能是由于网络问题。
      \n建议使用 VPN 及最新版 Edge 浏览器。
      \nConnection failed, if you are certain that the configuration is correct, it may be due to network issues.
      \nRecommended: VPN and the latest Edge browser.
      `;
      setIsConnected(false);
      setIsConnecting(false);
      setConnectMessage(tip);
      // alert(`${tip}\n${e}\n\nKey is "${localStorage.getItem('key')}"`);
      // window.location.href = '/';
      return;
    }


    // Set state variables
    startTimeRef.current = new Date().toISOString();
    setIsConnected(true);
    setIsConnecting(false);
    setRealtimeEvents([]);
    setItems(client.conversation.getItems());

    // Connect to microphone
    await wavRecorder.begin();

    // Connect to audio output
    await wavStreamPlayer.connect();

    const language = localStorage.getItem('language') || 'chinese';

    client.sendUserMessageContent([
      {
        type: `input_text`,
        text: language === 'chinese' ? clientHiChinese : clientHiEnglish
      }
    ]);

    if (client.getTurnDetectionType() === 'server_vad') {
      await wavRecorder.record((data) => client.appendInputAudio(data.mono));
    }
  }, []);

  /**
   * Disconnect and reset conversation state
   */
  const disconnectConversation = useCallback(async () => {
    setIsConnected(false);
    setIsConnecting(false);
    setRealtimeEvents([]);
    setItems([]);
    setMemoryKv({});
    setCoords({
      lat: 39.9841,
      lng: 116.3125
    });
    setMarker(null);

    const client = clientRef.current;
    client.disconnect();

    const wavRecorder = wavRecorderRef.current;
    await wavRecorder.end();

    const wavStreamPlayer = wavStreamPlayerRef.current;
    await wavStreamPlayer.interrupt();
  }, []);

  const deleteConversationItem = useCallback(async (id: string) => {
    const client = clientRef.current;
    client.deleteItem(id);
  }, []);

  /**
   * In push-to-talk mode, start recording
   * .appendInputAudio() for each sample
   */
  const startRecording = async () => {
    setIsRecording(true);
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const trackSampleOffset = await wavStreamPlayer.interrupt();
    if (trackSampleOffset?.trackId) {
      const { trackId, offset } = trackSampleOffset;
      await client.cancelResponse(trackId, offset);
    }
    await wavRecorder.record((data) => client.appendInputAudio(data.mono));
  };

  /**
   * In push-to-talk mode, stop recording
   */
  const stopRecording = async () => {
    try {
      const client = clientRef.current;
      const wavRecorder = wavRecorderRef.current;
      setIsRecording(false);
      await wavRecorder.pause();
      client.createResponse();
    } catch (e) {
      setIsConnecting(false);
      setIsConnected(false);
      setConnectMessage('Connection Failed. \nPlease check your network and reconnect.');
      clientRef.current.disconnect();
      console.error(e);
    }
  };

  /**
   * Switch between Manual <> VAD mode for communication
   */
  const changeTurnEndType = async (value: string) => {
    const client = clientRef.current;
    const wavRecorder = wavRecorderRef.current;
    if (value === 'none' && wavRecorder.getStatus() === 'recording') {
      await wavRecorder.pause();
    }

    try {
      client.updateSession({
        turn_detection: value === 'none' ? null : { type: 'server_vad' }
      });
      if (value === 'server_vad' && client.isConnected()) {
        await wavRecorder.record((data) => client.appendInputAudio(data.mono));
      }
      setCanPushToTalk(value === 'none');
    } catch (e) {
      setIsConnecting(false);
      setIsConnected(false);
      setConnectMessage('Connection Failed. \nPlease check your network and reconnect.');
      client.disconnect();
      console.error(e);
    }

  };

  /**
   * Auto-scroll the event logs
   */
  useEffect(() => {
    if (eventsScrollRef.current) {
      const eventsEl = eventsScrollRef.current;
      const scrollHeight = eventsEl.scrollHeight;
      // Only scroll if height has just changed
      if (scrollHeight !== eventsScrollHeightRef.current) {
        eventsEl.scrollTop = scrollHeight;
        eventsScrollHeightRef.current = scrollHeight;
      }
    }
  }, [realtimeEvents]);

  /**
   * Auto-scroll the conversation logs
   */
  useEffect(() => {
    const conversationEls = [].slice.call(
      document.body.querySelectorAll('[data-conversation-content]')
    );
    for (const el of conversationEls) {
      const conversationEl = el as HTMLDivElement;
      conversationEl.scrollTop = conversationEl.scrollHeight;
    }
  }, [items]);

  /**
   * Set up render loops for the visualization canvas
   */
  useEffect(() => {
    let isLoaded = true;

    const wavRecorder = wavRecorderRef.current;
    const clientCanvas = clientCanvasRef.current;
    let clientCtx: CanvasRenderingContext2D | null = null;

    const wavStreamPlayer = wavStreamPlayerRef.current;
    const serverCanvas = serverCanvasRef.current;
    let serverCtx: CanvasRenderingContext2D | null = null;

    const render = () => {
      if (isLoaded) {
        if (clientCanvas) {
          if (!clientCanvas.width || !clientCanvas.height) {
            clientCanvas.width = clientCanvas.offsetWidth;
            clientCanvas.height = clientCanvas.offsetHeight;
          }
          clientCtx = clientCtx || clientCanvas.getContext('2d');
          if (clientCtx) {
            clientCtx.clearRect(0, 0, clientCanvas.width, clientCanvas.height);
            const result = wavRecorder.recording
              ? wavRecorder.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              clientCanvas,
              clientCtx,
              result.values,
              '#95ec69',
              10,
              0,
              8
            );
          }
        }
        if (serverCanvas) {
          if (!serverCanvas.width || !serverCanvas.height) {
            serverCanvas.width = serverCanvas.offsetWidth;
            serverCanvas.height = serverCanvas.offsetHeight;
          }
          serverCtx = serverCtx || serverCanvas.getContext('2d');
          if (serverCtx) {
            serverCtx.clearRect(0, 0, serverCanvas.width, serverCanvas.height);
            const result = wavStreamPlayer.analyser
              ? wavStreamPlayer.getFrequencies('voice')
              : { values: new Float32Array([0]) };
            WavRenderer.drawBars(
              serverCanvas,
              serverCtx,
              result.values,
              '#ffffff',
              10,
              0,
              8
            );
          }
        }
        window.requestAnimationFrame(render);
      }
    };
    render();

    return () => {
      isLoaded = false;
    };
  }, []);

  /**
   * Core RealtimeClient and audio capture setup
   * Set all of our instructions, tools, events and more
   */
  useEffect(() => {
    // Get refs
    const wavStreamPlayer = wavStreamPlayerRef.current;
    const client = clientRef.current;

    // Set instructions
    setInstructions(getDefaultInstructions())
    client.updateSession({ instructions: getDefaultInstructions() });
    // Set transcription, otherwise we don't get user transcriptions back
    client.updateSession({ input_audio_transcription: { model: 'whisper-1' } });
    // Set voice
    client.updateSession({ voice: 'echo' });

    // Add tools
    client.addTool(
      {
        name: 'set_memory',
        description: 'Saves important data about the user into memory.',
        parameters: {
          type: 'object',
          properties: {
            key: {
              type: 'string',
              description:
                'The key of the memory value. Always use lowercase and underscores, no other characters.'
            },
            value: {
              type: 'string',
              description: 'Value can be anything represented as a string'
            }
          },
          required: ['key', 'value']
        }
      },
      async ({ key, value }: { [key: string]: any }) => {
        setMemoryKv((memoryKv) => {
          const newKv = { ...memoryKv };
          newKv[key] = value;
          return newKv;
        });
        return { ok: true };
      }
    );

    client.addTool(
      {
        name: 'get_weather',
        description:
          'Retrieves the weather for a given lat, lng coordinate pair. Specify a label for the location.',
        parameters: {
          type: 'object',
          properties: {
            lat: {
              type: 'number',
              description: 'Latitude'
            },
            lng: {
              type: 'number',
              description: 'Longitude'
            },
            location: {
              type: 'string',
              description: 'Name of the location'
            }
          },
          required: ['lat', 'lng', 'location']
        }
      },
      async ({ lat, lng, location }: { [key: string]: any }) => {
        setMarker({ lat, lng, location });
        setCoords({ lat, lng, location });
        const result = await fetch(
          `https://api.open-meteo.com/v1/forecast?latitude=${lat}&longitude=${lng}&current=temperature_2m,wind_speed_10m`
        );
        const json = await result.json();
        const temperature = {
          value: json.current.temperature_2m as number,
          units: json.current_units.temperature_2m as string
        };
        const wind_speed = {
          value: json.current.wind_speed_10m as number,
          units: json.current_units.wind_speed_10m as string
        };
        setMarker({ lat, lng, location, temperature, wind_speed });
        return json;
      }
    );

    /**
     * Dark mode tool
     */
    client.addTool(
      {
        name: 'dark_mode',
        description: 'Turn on / off dark mode.',
        parameters: {
          type: 'object',
          properties: {
            on: {
              type: 'boolean',
              description: 'bool of turn on or off dark mode.',
              default: true
            }
          }
        }
      }, ({ on }: { [on: string]: boolean }) => {
        setIsNightMode(on);
        return { ok: true };
      }
    );

    /**
     * Control app tool
     */
    client.addTool(
      {
        name: 'control_app',
        description: 'control your windows computer or mobile phone to complete some complex tasks.',
        parameters: {
          type: 'object',
          properties: {
            description: {
              type: 'string',
              description: 'description of the task',
              default: true
            }
          }
        }
      },
      async ({ description }: { [description: string]: string }) => {
        return { message: 'this feature is developing, And to be completed by November 2024' };
      }
    );

    /**
     * graphrag tool
     */
    client.addTool(
      {
        name: 'graphrag',
        description: 'RAG（Retrieval-Augmented Generation）. Talk to your data',
        parameters: {
          type: 'object',
          properties: {
            description: {
              type: 'string',
              description: 'description of the task',
              default: true
            }
          }
        }
      },
      async ({ description }: { [description: string]: string }) => {
        return { message: 'this feature is developing, And to be completed by November 2024' };
      }
    );

    client.addTool(news.definition, news.handler);
    client.addTool(exchange_rate_aim.definition, exchange_rate_aim.handler);
    client.addTool(exchange_rate_list.definition, exchange_rate_list.handler);
    client.addTool(exchange_rate_configs.definition, exchange_rate_configs.handler);
    client.addTool(products_recommend.definition, products_recommend.handler);
    client.addTool(location.definition, location.handler);
    client.addTool(feishu.definition, feishu.handler);
    client.addTool(demo.definition, demo.handler);
    client.addTool(quote.definition, quote.handler);
    client.addTool(stock_recommend.definition, stock_recommend.handler);
    // client.addTool(microsoftgraph.definition, microsoftgraph.handler);

    // handle realtime events from client + server for event logging
    client.on('realtime.event', (realtimeEvent: RealtimeEvent) => {
      setRealtimeEvents((realtimeEvents) => {
        const lastEvent = realtimeEvents[realtimeEvents.length - 1];
        if (lastEvent?.event.type === realtimeEvent.event.type) {
          // if we receive multiple events in a row, aggregate them for display purposes
          lastEvent.count = (lastEvent.count || 0) + 1;
          return realtimeEvents.slice(0, -1).concat(lastEvent);
        } else {
          return realtimeEvents.concat(realtimeEvent);
        }
      });
    });

    client.on('error', (event: any) => {
      console.error(event);
      setIsConnected(false);
      setIsConnecting(false);
    });

    client.on('close', (event: any) => {
      console.error(event);
      setIsConnected(false);
      setIsConnecting(false);
    });

    client.on('conversation.interrupted', async () => {
      const trackSampleOffset = await wavStreamPlayer.interrupt();
      if (trackSampleOffset?.trackId) {
        const { trackId, offset } = trackSampleOffset;
        await client.cancelResponse(trackId, offset);
      }
    });

    client.on('conversation.updated', async ({ item, delta }: any) => {

      const items = client.conversation.getItems();
      if (delta?.audio) {
        wavStreamPlayer.add16BitPCM(delta.audio, item.id);
      }

      if (item.status === 'completed' && item.formatted.audio?.length) {
        item.formatted.file = await WavRecorder.decode(
          item.formatted.audio,
          24000,
          24000
        );
      }

      const dataStore: { [key: string]: number } = {};
      // for item in items, get item and index
      for (const [index, item] of items.entries()) {

        if (item.type === 'function_call' && item?.formatted?.tool?.call_id) {
          dataStore[item.formatted.tool.call_id] = index;
          continue;
        }

        if (item.type === 'function_call_output') {
          const callId = item.call_id;
          const callIndex = dataStore[callId];
          if (callIndex !== undefined) {
            items[callIndex] = item;
            delete items[index];
          }
        }

      }


      setItems(items);
    });

    setItems(client.conversation.getItems());

    return () => {
      // cleanup; resets to defaults
      client.reset();
    };
  }, []);

  const memberRefs = useRef<{ [key: string]: HTMLDivElement | null }>({});

  const isHiddenTool = (item: ItemType) => {
    if (item?.formatted?.text && notDisplay.includes(item?.formatted?.text)) {
      return true;
    }

    if (item.type !== 'function_call_output') {
      return false;
    }

    if (JSON.parse(item?.output)?.result?.data?.length > 0) {
      return false;
    }

    if (JSON.parse(item?.output)?.products?.length > 0) {
      return false;
    }

    if (JSON.parse(item?.output)?.isRag == true) {
      return false;
    }

    return true;
  };


  /**
   * Render the application
   */
  return (
    <div data-component="ConsolePage">

      {/* <AdvancedSoundControl /> */}

      {/* <Loading /> */}

      <Painting client={clientRef.current} wavStreamPlayer={wavStreamPlayerRef.current} />

      <div className="content-top">

        <div className="content-title">
          <img src="/logomark.svg" alt="logo" />
          <h1>AI Agent Playground</h1>
        </div>

        {
          IS_DEBUG && (<span onClick={toggleNightMode}>
            {isNightMode ? '☀️' : '🌙'}
          </span>)
        }


        <span>
          PRC STU Azure Team
        </span>


      </div>
      <div className="content-main">

        <div className="content-logs container_bg">
          <div className="content-block events">

            {/*<div className="content-block-title">events</div>*/}
            {/*<div className="content-block-body" ref={eventsScrollRef}>*/}
            {/*  {!realtimeEvents.length && `awaiting connection...`}*/}
            {/*  {realtimeEvents.map((realtimeEvent, i) => {*/}
            {/*    const count = realtimeEvent.count;*/}
            {/*    const event = { ...realtimeEvent.event };*/}
            {/*    if (event.type === 'input_audio_buffer.append') {*/}
            {/*      event.audio = `[trimmed: ${event.audio.length} bytes]`;*/}
            {/*    } else if (event.type === 'response.audio.delta') {*/}
            {/*      event.delta = `[trimmed: ${event.delta.length} bytes]`;*/}
            {/*    }*/}
            {/*    return (*/}
            {/*      <div className="event" key={event.event_id}>*/}
            {/*        <div className="event-timestamp">*/}
            {/*          {formatTime(realtimeEvent.time)}*/}
            {/*        </div>*/}
            {/*        <div className="event-details">*/}
            {/*          <div*/}
            {/*            className="event-summary"*/}
            {/*            onClick={() => {*/}
            {/*              // toggle event details*/}
            {/*              const id = event.event_id;*/}
            {/*              const expanded = { ...expandedEvents };*/}
            {/*              if (expanded[id]) {*/}
            {/*                delete expanded[id];*/}
            {/*              } else {*/}
            {/*                expanded[id] = true;*/}
            {/*              }*/}
            {/*              setExpandedEvents(expanded);*/}
            {/*            }}*/}
            {/*          >*/}
            {/*            <div*/}
            {/*              className={`event-source ${*/}
            {/*                event.type === 'error'*/}
            {/*                  ? 'error'*/}
            {/*                  : realtimeEvent.source*/}
            {/*              }`}*/}
            {/*            >*/}
            {/*              {realtimeEvent.source === 'client' ? (*/}
            {/*                <ArrowUp />*/}
            {/*              ) : (*/}
            {/*                <ArrowDown />*/}
            {/*              )}*/}
            {/*              <span>*/}
            {/*                {event.type === 'error'*/}
            {/*                  ? 'error!'*/}
            {/*                  : realtimeEvent.source}*/}
            {/*              </span>*/}
            {/*            </div>*/}
            {/*            <div className="event-type">*/}
            {/*              {event.type}*/}
            {/*              {count && ` (${count})`}*/}
            {/*            </div>*/}
            {/*          </div>*/}
            {/*          {!!expandedEvents[event.event_id] && (*/}
            {/*            <div className="event-payload">*/}
            {/*              {JSON.stringify(event, null, 2)}*/}
            {/*            </div>*/}
            {/*          )}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    );*/}
            {/*  })}*/}
            {/*</div>*/}

          </div>
          <div className="content-block conversation">

            <div className="content-block-body" data-conversation-content>

              {isConnecting && (
                <div className={'waiting'}>
                  Connection...
                </div>
              )}

              {!isConnecting && !isConnected && (
                <div className={'waiting'}>
                  {connectMessage}
                </div>
              )}


              {items.map((conversationItem, i) => {

                if (isHiddenTool(conversationItem)) {
                  return null;
                }

                return (

                  <div className={`conversation-item ${conversationItem.role === 'user' ? 'user' : 'assistant'}`}
                    key={conversationItem.id}>

                    <div className={`speaker ${conversationItem.role === 'user' ? 'user' : 'assistant'}`}>
                    </div>

                    <div className={`speaker-content ${conversationItem.role === 'user' ? 'user' : 'assistant'}`}>


                      <div
                        className="close"
                        onClick={() =>
                          deleteConversationItem(conversationItem.id)
                        }
                      >
                        <X />
                      </div>


                      {/* tool call */}
                      {!!conversationItem.formatted.tool && (
                        <div className="loading-spinner" key={conversationItem.id}>
                          <div className="spinner" key={conversationItem.id + 'spinner'}></div>
                        </div>
                      )}

                      {/* tool response */}
                      {conversationItem.type === 'function_call_output' && (
                        <div>

                          {JSON.parse(conversationItem?.output)?.result?.data.map((item: any) => {
                            return <img src={item.url}
                              key={conversationItem.id}
                              alt="image"
                              className='painting_img' />
                          })}

                          {/* {conversationItem?.output} */}

                          {JSON.parse(conversationItem?.output)?.products && <ProductList products={products} />}

                        </div>
                      )}

                      {/*user message*/}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'user' && (
                          <div>

                            {conversationItem.formatted.transcript ||
                              (conversationItem.formatted.audio?.length
                                ? '(awaiting transcript)'
                                : conversationItem.formatted.text ||
                                '(item sent)')}

                          </div>
                        )}

                      {/*assistant message*/}
                      {!conversationItem.formatted.tool &&
                        conversationItem.role === 'assistant' && (
                          <div>
                            <ReactMarkdown
                              components={{
                                a: ({ node, ...props }) => (
                                  <a {...props} target="_blank" rel="noopener noreferrer">
                                    {props.children}
                                  </a>
                                )
                              }}
                            >
                              {conversationItem.formatted.transcript ||
                                conversationItem.formatted.text ||
                                '(truncated)'}
                            </ReactMarkdown>
                          </div>
                        )}

                      {/*file message*/}
                      {conversationItem.formatted.file && (
                        <audio
                          src={conversationItem.formatted.file.url}
                          controls
                        />
                      )}

                    </div>
                  </div>
                );
              })}

            </div>


            <div className="visualization">
              <div className="visualization-entry server">
                <canvas ref={serverCanvasRef} />
              </div>
              <div className="visualization-entry client">
                <canvas ref={clientCanvasRef} />
              </div>
            </div>

          </div>
        </div>

        <div className="content-right">

          <CameraComponent client={clientRef.current} wavStreamPlayer={wavStreamPlayerRef.current} />

          {/* <RagComponent client={clientRef.current} /> */}

          <EndpointSettingComponent client={clientRef.current} />

          {isConnected && (<FileUploadComponent client={clientRef.current} />)}

          {/* <TodoComponent /> */}

          {!isConnected && (
            <div className="phone_bar container_bg">
              {/*{connectMessage}*/}
              <div>暂不支持移动端</div>
              <div>请使用电脑体验</div>
            </div>
          )}


          {isConnected && (
            <div className="content-actions container_bg">
              <Toggle
                defaultValue={false}
                labels={['Manual', 'VAD']}
                values={['none', 'server_vad']}
                onChange={(_, value) => changeTurnEndType(value)}
              />
            </div>
          )
          }

          {isConnected && canPushToTalk && (
            <div className="content-actions">
              <Button
                label={isRecording ? 'Release to send' : 'Push to talk'}
                icon={Mic}
                className={'container_bg'}
                buttonStyle={isRecording ? 'alert' : 'regular'}
                disabled={!isConnected || !canPushToTalk}
                onMouseDown={startRecording}
                onMouseUp={stopRecording}
              />
            </div>
          )}

          <div className="content-actions">
            <Button
              disabled={isConnecting}
              className={'container_bg'}
              label={isConnected ? 'Disconnect' : (isConnecting ? 'Connecting' : 'Connect')}
              icon={isConnected ? X : Zap}
              buttonStyle={isConnected ? 'regular' : 'action'}
              onClick={
                isConnected ? disconnectConversation : connectConversation
              }
            />
          </div>

          {/*<div className="content-block map">*/}
          {/*  <div className="content-block-title">get_weather()</div>*/}
          {/*  <div className="content-block-title bottom">*/}
          {/*    {marker?.location || 'not yet retrieved'}*/}
          {/*    {!!marker?.temperature && (*/}
          {/*      <>*/}
          {/*        <br />*/}
          {/*        🌡️ {marker.temperature.value} {marker.temperature.units}*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*    {!!marker?.wind_speed && (*/}
          {/*      <>*/}
          {/*        {' '}*/}
          {/*        🍃 {marker.wind_speed.value} {marker.wind_speed.units}*/}
          {/*      </>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*  <div className="content-block-body full">*/}
          {/*    {coords && (*/}
          {/*      <Map*/}
          {/*        center={[coords.lat, coords.lng]}*/}
          {/*        location={coords.location}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</div>*/}


          {/*<div className="content-block kv">*/}
          {/*  <div className="content-block-title">set_memory()</div>*/}
          {/*  <div className="content-block-body content-kv">*/}
          {/*    {JSON.stringify(memoryKv, null, 2)}*/}
          {/*  </div>*/}
          {/*</div>*/}


        </div>

      </div>
    </div>
  );
}
