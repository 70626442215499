import React, { useState, useEffect } from 'react';
import { FaRegEyeSlash, FaRegEye } from 'react-icons/fa';
import { Settings, X } from 'react-feather';
import { Button } from '../components/button/Button';
import './Settings.scss';
import { RealtimeClient } from '@theodoreniu/realtime-api-beta';
import Dropdown from './Dropdown';
interface ChildComponentProps {
  client: RealtimeClient;
}

const EndpointKeyComponent: React.FC<ChildComponentProps> = ({ client }) => {

  // ----------------------------------------------------------------------------
  const [endpoint, setEndpoint] = useState(localStorage.getItem('endpoint') || '');
  const [key, setKey] = useState(localStorage.getItem('key') || '');

  useEffect(() => {
    localStorage.setItem('endpoint', endpoint.trim());
    localStorage.setItem('key', key.trim());
  }, [endpoint, key]);

  const handleEndpointChange = (e: any) => {
    setEndpoint(e.target.value);
  };

  const handleKeyChange = (e: any) => {
    setKey(e.target.value);
  };


  // ----------------------------------------------------------------------------
  const [feishuHook, setFeishuHook] = useState(localStorage.getItem('feishuHook') || '');
  const [quoteToken, setQuoteToken] = useState(localStorage.getItem('quoteToken') || '');
  const [newsKey, setNewsKey] = useState(localStorage.getItem('newsKey') || '');

  useEffect(() => {
    localStorage.setItem('feishuHook', feishuHook.trim());
    localStorage.setItem('quoteToken', quoteToken.trim());
    localStorage.setItem('newsKey', newsKey.trim());
  }, [feishuHook, quoteToken, newsKey]);

  const handleFeishuHookChange = (e: any) => {
    setFeishuHook(e.target.value);
  };

  const handleQuoteTokenChange = (e: any) => {
    setQuoteToken(e.target.value);
  };

  const handleNewsKeyChange = (e: any) => {
    setNewsKey(e.target.value);
  };

  // ----------------------------------------------------------------------------
  const [dallTargetUri, setDallTargetUri] = useState(localStorage.getItem('dallTargetUri') || '');
  const [dallApiKey, setDallApiKey] = useState(localStorage.getItem('dallApiKey') || '');

  useEffect(() => {
    localStorage.setItem('dallTargetUri', dallTargetUri.trim());
    localStorage.setItem('dallApiKey', dallApiKey.trim());
  }, [dallTargetUri, dallApiKey]);

  const handleDallTargetUriChange = (e: any) => {
    setDallTargetUri(e.target.value);
  };

  const handleDallApiKeyChange = (e: any) => {
    setDallApiKey(e.target.value);
  };


  // ----------------------------------------------------------------------------
  const [completionTargetUri, setCompletionTargetUri] = useState(localStorage.getItem('completionTargetUri') || '');
  const [completionApiKey, setCompletionApiKey] = useState(localStorage.getItem('completionApiKey') || '');

  useEffect(() => {
    localStorage.setItem('completionTargetUri', completionTargetUri.trim());
    localStorage.setItem('completionApiKey', completionApiKey.trim());
  }, [completionTargetUri, completionApiKey]);

  const handleCompletionTargetUriChange = (e: any) => {
    setCompletionTargetUri(e.target.value);
  };

  const handleCompletionApiKeyChange = (e: any) => {
    setCompletionApiKey(e.target.value);
  };

  // ----------------------------------------------------------------------------
  const [mxnzpAppId, setMxnzpAppId] = useState(localStorage.getItem('mxnzpAppId') || '');
  const [mxnzpAppSecret, setMxnzpAppSecret] = useState(localStorage.getItem('mxnzpAppSecret') || '');

  useEffect(() => {
    localStorage.setItem('mxnzpAppId', mxnzpAppId.trim());
    localStorage.setItem('mxnzpAppSecret', mxnzpAppSecret.trim());
  }, [mxnzpAppId, mxnzpAppSecret]);

  const handleMxnzpAppIdChange = (e: any) => {
    setMxnzpAppId(e.target.value);
  };

  const handleMxnzpAppSecretChange = (e: any) => {
    setMxnzpAppSecret(e.target.value);
  };

  // ----------------------------------------------------------------------------
  const [isVisible, setIsVisible] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [selected, setSelected] = useState<string>(localStorage.getItem('language') || 'chinese');

  const handleDropdownChange = (value: string) => {
    setSelected(value);
    localStorage.setItem('language', value)
  };

  const options = [
    { value: 'chinese', label: 'Chinese' },
    { value: 'english', label: 'English' },
  ];

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsModalOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const SettingsRealtime = () => {
    return <div>
      <div className="settings-label">Target URI</div>
      <input type={'text'}
        className="settings-input"
        value={endpoint}
        placeholder={'https://xxx.openai.azure.com/openai/realtime?api-version=2024-10-01-preview&deployment=xxx'}
        onChange={handleEndpointChange} />

      <div className="settings-label">Key
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={key}
        placeholder={''}
        onChange={handleKeyChange} />

      <div className="settings-label">Default Language</div>
      <Dropdown options={options} selectedValue={selected} onChange={handleDropdownChange} />
    </div>
  };

  const DALLE = () => {
    return <div>
      <div className="settings-label">Target URI</div>
      <input type={'text'}
        className="settings-input"
        value={dallTargetUri}
        placeholder={'https://xxx.openai.azure.com/openai/deployments/dall-e-3/images/generations?api-version=2024-02-01'}
        onChange={handleDallTargetUriChange} />

      <div className="settings-label">Key
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={dallApiKey}
        placeholder={''}
        onChange={handleDallApiKeyChange} />

    </div>
  };

  const SettingsCompletion = () => {
    return <div>
      <div className="settings-label">Target URI</div>
      <input type={'text'}
        className="settings-input"
        value={completionTargetUri}
        placeholder={'https://xxxx.openai.azure.com/openai/deployments/gpt-4o-mini/chat/completions?api-version=2024-08-01-preview'}
        onChange={handleCompletionTargetUriChange} />

      <div className="settings-label">Key
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={completionApiKey}
        placeholder={''}
        onChange={handleCompletionApiKeyChange} />

    </div>
  };

  const SettingsTokens = () => {
    return <div>

      <div className="settings-label">
        <a href="https://open.feishu.cn/open-apis/bot/v2/hook/xxx" target="_blank">Feishu Bot</a>
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={feishuHook}
        placeholder={''}
        onChange={handleFeishuHookChange} />

      <div className="settings-label">
        <a href="https://finnhub.io/" target="_blank">Finnhub</a>
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={quoteToken}
        placeholder={''}
        onChange={handleQuoteTokenChange} />

      <div className="settings-label">
        <a href="https://www.showapi.com/" target="_blank">News</a>
        <span
          className="settings-label-show"
          onClick={toggleVisibility}
        >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
      </div>
      <input type={isVisible ? 'text' : 'password'}
        className="settings-input"
        value={newsKey}
        placeholder={''}
        onChange={handleNewsKeyChange} />

      <div className='settings_inline'>

        <div className='block'>
          <div className="settings-label">
            <a href="https://www.mxnzp.com/" target="_blank">Mxnzp AppId</a>
          </div>
          <input type={'text'}
            className="settings-input"
            value={mxnzpAppId}
            placeholder={''}
            onChange={handleMxnzpAppIdChange} />
        </div>

        <div className='block'>
          <div className="settings-label">
            <a href="https://www.mxnzp.com/" target="_blank">Mxnzp AppSecret</a>
            <span
              className="settings-label-show"
              onClick={toggleVisibility}
            >{isVisible ? <FaRegEye /> : <FaRegEyeSlash />}</span>
          </div>
          <input type={isVisible ? 'text' : 'password'}
            className="settings-input"
            value={mxnzpAppSecret}
            placeholder={''}
            onChange={handleMxnzpAppSecretChange} />
        </div>

      </div>
    </div>

  };

  const REAL_TIME_API = 'Realtime';
  const DALL_E = 'Dall-E-3';
  const COMPLETION = 'Completion';
  const TOKENS = 'Third-party API';

  const [activeTab, setActiveTab] = useState(REAL_TIME_API);

  const renderContent = () => {
    switch (activeTab) {
      case REAL_TIME_API:
        return <SettingsRealtime />;
      case DALL_E:
        return <DALLE />;
      case COMPLETION:
        return <SettingsCompletion />;
      case TOKENS:
        return <SettingsTokens />;
      default:
        return <SettingsRealtime />;
    }
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  const handleClick = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className='content-actions'>


      <Button className='container_bg'
        label={client.isConnected() ? "Disconnect to Settings" : 'Settings'}
        icon={Settings}
        disabled={client.isConnected() ? true : false}
        onClick={handleClick}
      />

      {isModalOpen && (
        <div className='settings-modal'>
          <div className='settings-modal-content'>

            <button className='settings-modal-close' onClick={closeModal}><X /></button>

            <div className='settings-modal-header'>Settings<div className="settings-label-tip">(Local Only)</div></div>

            <div>
              <div className="settings-tab-buttons">
                <button onClick={() => setActiveTab(REAL_TIME_API)} className={activeTab === REAL_TIME_API ? 'active' : ''}>{REAL_TIME_API}</button>
                <button onClick={() => setActiveTab(COMPLETION)} className={activeTab === COMPLETION ? 'active' : ''}>{COMPLETION}</button>
                <button onClick={() => setActiveTab(DALL_E)} className={activeTab === DALL_E ? 'active' : ''}>{DALL_E}</button>
                <button onClick={() => setActiveTab(TOKENS)} className={activeTab === TOKENS ? 'active' : ''}>{TOKENS}</button>
              </div>

              <div className="tab-content">
                {renderContent()}
              </div>

            </div>


          </div>
        </div>
      )}




    </div>
  );
};


export default EndpointKeyComponent;
