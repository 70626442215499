import { RealtimeClient } from '@theodoreniu/realtime-api-beta';
import React, { useState, useRef } from 'react';
import { fileUploadInstructions, fileUploadTooBig } from '../utils/conversation_config';
import './FileUploadComponent.scss';
import { Upload } from 'react-feather';
import { Button } from '../components/button/Button';
import { DATA_BEGIN, DATA_END, getInstructions, replaceInstructions, setInstructions } from '../utils/instructions';

interface ChildComponentProps {
    client: RealtimeClient;
}

const FileUploadComponent: React.FC<ChildComponentProps> = ({ client }) => {
    const [file, setFile] = useState<File | null>(null);
    const [fileName, setFileName] = useState<string>('Upload File');
    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = event.target.files?.[0];

        // 检查文件是否符合格式
        if (selectedFile && (selectedFile.type === 'text/plain' || selectedFile.name.endsWith('.xlsx'))) {
            setFileName(selectedFile.name); // 更新文件名
            // 读取并处理文件内容
            if (selectedFile.type === 'text/plain') {
                const text = await selectedFile.text();
                console.log("TXT文件内容:", text);
                updateSession(text);
            } else if (selectedFile.name.endsWith('.xlsx')) {
                const reader = new FileReader();
                reader.onload = (e) => {
                    const data = e.target?.result;
                    if (data) {
                        // 使用 `xlsx` 库解析 Excel 文件
                        import("xlsx").then((XLSX) => {
                            const workbook = XLSX.read(data, { type: "array" });
                            const firstSheetName = workbook.SheetNames[0];
                            const worksheet = workbook.Sheets[firstSheetName];
                            const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
                            console.log("Excel文件内容:", jsonData);
                            updateSession(JSON.stringify(jsonData));
                        });
                    }
                };
                reader.readAsArrayBuffer(selectedFile);
            }
        } else {
            alert("只允许上传 .txt 和 .xlsx 文件");
            event.target.value = ''; // 清除文件输入
        }
    };

    const updateSession = (content: string) => {

        if (client.isConnected()) {

            if (content.length > 20000) {
                client.sendUserMessageContent([
                    {
                        type: `input_text`,
                        text: fileUploadTooBig
                    }
                ]);
                return;
            }

            console.log('content', content.length);

            client.updateSession({
                instructions: updateDataFile(content)
            });
            console.log('update instructions');

            client.sendUserMessageContent([
                {
                    type: `input_text`,
                    text: fileUploadInstructions
                }
            ]);

        }
    }

    const updateDataFile = (content: string) => {
        const instructions = getInstructions()
        const regex = new RegExp(`${DATA_BEGIN}[\\s\\S]*?${DATA_END}`, "g");

        const new_instructions = instructions.replace(regex, `${DATA_BEGIN}\n${content}\n${DATA_END}`);
        setInstructions(new_instructions);
        return new_instructions;
    };

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };


    return (
        <div className='content-actions'>

            <Button
                label={fileName}
                icon={Upload}
                className={'container_bg'}
                buttonStyle={'regular'}
                onClick={handleButtonClick}
            />

            <input
                type="file"
                accept=".txt, .xlsx"
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{ display: 'none' }}
            />

        </div>
    );
};

export default FileUploadComponent;
