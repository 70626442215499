

export async function getCompletion(messages: any): Promise<string> {
    const completionApiKey = localStorage.getItem('completionApiKey') || '';
    const completionTargetUri = localStorage.getItem('completionTargetUri') || '';

    if (!completionApiKey || !completionTargetUri) {
        return "Missing API key or target URI, Please check your settings";
    }

    const headers = {
        "Content-Type": "application/json",
        "api-key": completionApiKey,
    };

    const raw = JSON.stringify({
        "messages": messages
    });

    try {
        const response = await fetch(completionTargetUri, {
            method: "POST",
            headers: headers,
            body: raw
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data?.choices[0]?.message?.content || "error";
    } catch (error) {
        console.error("Error fetching completion:", error);
        return "Error fetching completion";
    }
}


export async function getJsonData(messages: any): Promise<string> {
    const completionApiKey = localStorage.getItem('completionApiKey') || '';
    const completionTargetUri = localStorage.getItem('completionTargetUri') || '';

    if (!completionApiKey || !completionTargetUri) {
        return "Missing API key or target URI, Please check your settings";
    }

    const headers = {
        "Content-Type": "application/json",
        "api-key": completionApiKey,
    };

    const raw = JSON.stringify({
        messages: messages,
        // response_format: {
        //     // See /docs/guides/structured-outputs
        //     type: "json_schema",
        //     json_schema: {
        //         name: "product_schema",
        //         schema: {
        //             type: "object",
        //             properties: {
        //                 name: {
        //                     description: "The name of the product.",
        //                     type: "string"
        //                 },
        //                 price: {
        //                     description: "The price of the product.",
        //                     type: "number"
        //                 },
        //                 description: {
        //                     description: "The description of the product.",
        //                     type: "string"
        //                 },
        //                 image: {
        //                     description: "The image url of the product.",
        //                     type: "string"
        //                 },
        //             },
        //             additionalProperties: false
        //         }
        //     }
        // }
    });

    try {
        const response = await fetch(completionTargetUri, {
            method: "POST",
            headers: headers,
            body: raw
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data?.choices[0]?.message?.content || "error";
    } catch (error) {
        console.error("Error fetching completion:", error);
        return "Error fetching completion";
    }
}


export async function getImages(prompt: string): Promise<string> {
    const dallApiKey = localStorage.getItem('dallApiKey') || '';
    const dallTargetUri = localStorage.getItem('dallTargetUri') || '';

    if (!dallApiKey || !dallTargetUri) {
        return "Missing API key or target URI, Please check your settings";
    }

    const headers = {
        "Content-Type": "application/json",
        "api-key": dallApiKey,
    };

    try {
        const response = await fetch(dallTargetUri, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                'prompt': prompt,
                'n': 1,
                'size': '1024x1024',
                // 'response_format': 'b64_json'
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        data.message = '绘画完成，请你不要阅读网址，只阅读图画简介'

        return data || "error. please try again later.";
    } catch (error) {
        console.error("Error fetching completion:", error);
        return "Error fetching completion";
    }
}


export async function editImages(prompt: string, image_base_64: string): Promise<string> {
    const completionApiKey = localStorage.getItem('completionApiKey') || '';
    const completionTargetUri = localStorage.getItem('completionTargetUri') || '';

    if (!completionApiKey || !completionTargetUri) {
        return "Missing API key or target URI, Please check your settings";
    }

    const headers = {
        "Content-Type": "application/json",
        "api-key": completionApiKey,
    };

    // image_base_64 to io read
    const base64 = image_base_64.split(',')[1];
    const imageData = atob(base64);
    const uint8Array = new Uint8Array(imageData.length);

    for (let i = 0; i < imageData.length; i++) {
        uint8Array[i] = imageData.charCodeAt(i);
    }

    const imageBlob = new Blob([uint8Array], { type: "image/png" });

    try {
        const response = await fetch(completionTargetUri, {
            method: "POST",
            headers: headers,
            body: JSON.stringify({
                'prompt': prompt,
                'n': 1,
                'size': '1024x1024',
                'image': imageBlob,
            })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        return data || "error";
    } catch (error) {
        console.error("Error fetching completion:", error);
        return "Error fetching completion";
    }
}